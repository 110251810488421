import { InterludeAssociationOutput } from '@amzn/amazon-music-interlude-catalog-manager-client';
import { QcarOutput } from '@amzn/amazon-music-interlude-catalog-manager-client';
/** An asset fetched from the server is guaranteed to have an ID */
export interface ServerAsset extends Asset {
    id: string;
}

export enum AssetCategories {
    DEFAULT_ARTIST_IMAGE = 'DEFAULT_ARTIST_IMAGE',
}

export enum ContentTypes {
    SPOTLIGHT = 'SPOTLIGHT',
    NEW_RELEASE_ANNOUCEMENT = 'NEW_RELEASE_ANNOUNCEMENT',
}

export interface StationMetadata {
    station_name: string;
    station_key: string;
    marketplaces: string[];
    tiers: string[];
}

export interface ServerQcar {
    qcar: QcarOutput;
    station_metadata?: StationMetadata;
}

export interface Asset {
    /** ID of the asset */
    id?: string;
    /** Type of asset (TTS, AUDIO, IMAGE) */
    type?: string;
    /** Description */
    descr?: string;
    /** Language */
    lang?: string;
    /** Marketplace/territory */
    mtr?: string;
    /** Original filename */
    origfile?: string;
    /** ExpirationDate date-time */
    exp_date?: string;
    /** Voice upsell batch of which the asset is a part of */
    batch_number?: number;
    /** Author */
    author?: string;
    /** Template (TTS) */
    template?: string;
    /** S3 Sources (AUDIO/MEDIA) */
    srcs?: {
        s3_bucket?: string;
        s3_key?: string;
        width_pixels?: number;
        height_pixels?: number;
    }[];
    /** Automatically populate the name of an artist */
    auto_values?: boolean;
    /** Category of the image asset (MEDIA + Image only) */
    category?: string;
    /** ASIN of the artist associated with the asset */
    asin?: string;
    /** Presigned URL for AUDIO/MEDIA resources */
    presigned_url?: string;
    /** Stage where the data for the interlude was read from */
    data_stage?: string;
    /** Name of the source file (AUDIO/MEDIA) - used only for create/update */
    fileName?: string;
    /** Name of the source file (AUDIO/MEDIA) - used only for create/update */
    extension?: string;
    /** Width of the image (MEDIA) - used only for create/update */
    width_pixels?: number;
    /** Height of the image (MEDIA) - used only for create/update */
    height_pixels?: number;
}

export interface Interlude {
    /** Interlude ID */
    id?: string;
    /** Assembly type */
    a_type?: string;
    /** Position */
    pos?: string;
    /** Rank */
    rank?: number;
    /** Asset IDs */
    asset_ids?: {
        text?: string[];
        media?: string[];
    };
    /** Image assets */
    imgs?: {
        id: string;
    }[];
    /** Languages */
    lang?: string[];
    /** Territories */
    mtr_list?: string[];
    /** Tiers */
    tiers?: string[];
    /** Content source */
    src?: string;
    /** Content type */
    c_type?: string;
    /** Creation date-time */
    crt_time?: string;
    /** Description (internal only) */
    descr?: string;
    /** Flag: is editorial? */
    is_edtrl?: boolean;
    /** Flag: is explicit? */
    is_explicit?: boolean;
    /** Flag: is mixable? */
    is_mixable?: boolean;
    /** Flag: is skippable? */
    is_skippable?: boolean;
    /** Stage where the data for the interlude was read from */
    data_stage?: string;
    /** Primary display string */
    pri_txt?: string;
    /** Secondary display string */
    sec_txt?: string;
    /** Scheduling information */
    schedule?: InterludeSchedule;
    /** Flattened lists of assets (Internal only) */
    assets?: {
        text: string[];
        media: string[];
        images: { id: string }[];
    };
    /** Flattened list of text/media assets (Internal only) */
    all_asset_ids?: string;
    /** Flattened list of image assets (Internal only) */
    all_imgs?: string;
    /** Associated tracks/albums/artists/stations with this interlude - only available when fetching all interludes */
    associations?: string;
    /** Associated tracks with this interlude - only available when fetching single interlude */
    track_asin?: string;
    /** Associated albums with this interlude - only available when fetching single interlude */
    album_asin?: string;
    /** Associated artists with this interlude - only available when fetching single interlude */
    artist_asin?: string;
    /** Associated stations with this interlude - only available when fetching single interlude */
    stn_key?: string;
    /** Associated titleset asin with this interlude - only available when fetching single interlude */
    titleset_asin?: string;
}

/** An interlude fetched from the server is guaranteed to have an ID */
export interface ServerInterlude extends Interlude {
    id: string;
}

export interface InterludeSchedule {
    useLocal: boolean;
    start?: {
        enabled: boolean;
        dateTime?: string;
    };
    end?: {
        enabled: boolean;
        dateTime?: string;
    };
    daysOfWeek?: string;
    hoursOfDay?: {
        enabled: boolean;
        start?: string;
        end?: string;
    };
}
export interface QueueItem {
    /** ID */
    id?: string;
    /** Rendered interlude ID */
    rendered_interlude_id?: string;
    /** Timestamp */
    timestamp?: number;
    /** Was the item vended */
    are_vended?: boolean;
    /** Content type (e.g. SONG_ID) */
    content_type?: string;
    /** Position (e.g. INTRO) */
    position?: string;
    /** Interlude template ID */
    template_id?: string;
    /** Assets */
    assets?: {
        asset_id?: string;
    }[];
}

export interface StagedResource {
    /** ID (e.g. IL-1234) */
    id?: string;
    /** Type (e.g. INTERLUDE or ASSET) */
    type?: string;
    /** Content type */
    c_type?: string;
    /** Description/Display Title */
    descr?: string;
    /** Content source */
    src?: string;
    /** Assembly type */
    a_type?: string;
    /** Speaker */
    author?: string;
}

export interface PresignedUrl {
    file_name: string;
    url: string;
}

export interface ImageMetadata {
    url: string;
    size: string;
    heightPx: number;
    widthPx: number;
}

export interface ArtistMetadata {
    id: string;
    idType: string;
    name?: string;
    dmArtistAsin?: string;
    contributorAsin?: string;
    images?: ImageMetadata[];
}

export interface Report {
    report_type?: string;
    tracklist_source?: string;
    file_uri?: string;
    station_key?: string;
    tier?: string;
    territory?: string;
}

export type StringMap = { [index: string]: string | StringMap };

export type PaginatedInterlude = { interludes: ServerInterlude[]; nextToken?: string };

export type PaginatedAssociations = { associations: InterludeAssociationOutput[]; nextToken?: string };
