import { Select } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react-v3';

export const service_tier_options: Select.Option[] = [
    {
        label: 'FREE TIER',
        id: 'NIGHTWING',
        description: 'Amazon Music Free Tier',
    },
    {
        label: 'SONIC RUSH',
        id: 'SONIC_RUSH',
        description: 'Sonic Rush tier',
    },
    {
        label: 'PRIME',
        id: 'PRIME',
        description: 'Prime Music tier',
    },
    {
        label: 'UNLIMITED',
        id: 'UNLIMITED',
        description: 'Amazon Music Unlimited',
    },
    {
        label: 'AMAZON MUSIC HD',
        id: 'AMAZON MUSIC HD',
        description: 'Amazon Music HD',
    },
];
export const service_tier_default: Select.Option[] = [
    {
        label: 'FREE TIER',
        id: 'NIGHTWING',
        description: 'Amazon Music Free Tier',
    },
    {
        label: 'SONIC RUSH',
        id: 'SONIC_RUSH',
        description: 'Sonic Rush tier',
    },
    {
        label: 'PRIME',
        id: 'PRIME',
        description: 'Prime Music tier',
    },
    {
        label: 'UNLIMITED',
        id: 'UNLIMITED',
        description: 'Amazon Music Unlimited',
    },
    {
        label: 'AMAZON MUSIC HD',
        id: 'AMAZON MUSIC HD',
        description: 'Amazon Music HD',
    },
];
export const available_language_options: Select.Option[] = [
    {
        label: 'en-US',
        id: 'en-US',
        description: 'English (US)',
    },
    {
        label: 'en-GB',
        id: 'en-GB',
        description: 'English (UK)',
    },
    {
        label: 'en-CA',
        id: 'en-CA',
        description: 'English (Canada)',
    },
    {
        label: 'pt-BR',
        id: 'pt-BR',
        description: 'Portuguese (Brazil)',
    },
    {
        label: 'fr-FR',
        id: 'fr-FR',
        description: 'French (France)',
    },
    {
        label: 'fr-CA',
        id: 'fr-CA',
        description: 'French (Canada)',
    },
    {
        label: 'es-MX',
        id: 'es-MX',
        description: 'Spanish (Mexico)',
    },
    {
        label: 'es-US',
        id: 'es-US',
        description: 'Spanish (US)',
    },
    {
        label: 'es-ES',
        id: 'es-ES',
        description: 'Spanish (Spain)',
    },

    {
        label: 'de-AT',
        id: 'de-AT',
        description: 'German (Austria)',
    },

    {
        label: 'en-IN',
        id: 'en-IN',
        description: 'English (India)',
    },

    {
        label: 'hi-IN',
        id: 'hi-IN',
        description: 'Hindi (India)',
    },
    {
        label: 'it-IT',
        id: 'it-IT',
        description: 'Italian (Italy)',
    },
    {
        label: 'ja-JP',
        id: 'ja-JP',
        description: 'Japanese (Japan)',
    },
    {
        label: 'de-DE',
        id: 'de-DE',
        description: 'German (Germany)',
    },
];

export const available_language_default: Select.Option[] = [
    {
        label: 'en-US',
        id: 'en-US',
        description: 'English (US)',
    },
    {
        label: 'en-CA',
        id: 'en-CA',
        description: 'English (Canada)',
    },
];

export const available_market_options: Select.Option[] = [
    {
        label: 'US',
        id: 'US',
        description: 'United States',
    },
    {
        label: 'UK',
        id: 'UK',
        description: 'United Kingdom',
    },
    {
        label: 'DE',
        id: 'DE',
        description: 'Germany',
    },
    {
        label: 'JP',
        id: 'JP',
        description: 'Japan',
    },
    {
        label: 'FR',
        id: 'FR',
        description: 'France',
    },
    {
        label: 'IT',
        id: 'IT',
        description: 'Italy',
    },
    {
        label: 'ES',
        id: 'ES',
        description: 'Spain',
    },
    {
        label: 'CA',
        id: 'CA',
        description: 'Canada',
    },
    {
        label: 'MX',
        id: 'MX',
        description: 'Mexico',
    },
];

export const available_market_default: Select.Option[] = [
    {
        label: 'US',
        id: 'US',
        description: 'United States',
    },
    {
        label: 'CA',
        id: 'CA',
        description: 'Canada',
    },
];

export const day_list: Select.Option[] = [
    {
        label: 'Monday',
        id: 'Monday',
    },
    {
        label: 'Tuesday',
        id: 'Tuesday',
    },
    {
        label: 'Wednesday',
        id: 'Wednesday',
    },
    {
        label: 'Thursday',
        id: 'Thursday',
    },
    {
        label: 'Friday',
        id: 'Friday',
    },
    {
        label: 'Saturday',
        id: 'Saturday',
    },
    {
        label: 'Sunday',
        id: 'Sunday',
    },
];

export const position_list: Select.Option[] = [
    {
        label: 'INTRO',
        id: 'INTRO',
    },
    {
        label: 'OUTRO',
        id: 'OUTRO',
    },
    {
        label: 'BRIDGE',
        id: 'BRIDGE',
    },
];

export const asset_type: Select.Option[] = [
    {
        label: 'Audio',
        id: 'AUDIO',
    },
    {
        label: 'TTS',
        id: 'TTS',
    },
    {
        label: 'Image',
        id: 'IMAGE',
    },
];

export const report_type: Select.Option[] = [
    {
        label: 'Coverage',
        id: 'COVERAGE',
    },
    {
        label: 'Simulation',
        id: 'SIMULATION',
    },
];

export const service_tiers_for_reports: Select.Option[] = [
    {
        label: 'Free Tier',
        id: 'NIGHTWING',
        description: 'Amazon Music Free Tier',
    },
    {
        label: 'Prime',
        id: 'PRIME',
        description: 'Prime Music tier',
    },
    {
        label: 'Unlimited',
        id: 'UNLIMITED',
        description: 'Amazon Music Unlimited',
    },
    {
        label: 'Sonic Rush',
        id: 'SONIC_RUSH',
        description: 'Sonic Rush tier',
    },
];

export const tracklist_source: Select.Option[] = [
    {
        label: 'File Upload',
        id: 'FILE',
    },
    {
        label: 'Station Key',
        id: 'STATION_KEY',
    },
];

export const simulation_tracklist_source: Select.Option[] = [
    {
        label: 'Station Key',
        id: 'STATION_KEY',
    },
];

export const assembly_type: Select.Option[] = [
    {
        label: 'Audio',
        id: 'AUDIO',
    },
    {
        label: 'TTS',
        id: 'TTS',
    },
];

export const content_type: Select.Option[] = [
    {
        label: 'BILLBOARD_CHARTS_SONG_EXACT_POSITION',
        id: 'BILLBOARD_CHARTS_SONG_EXACT_POSITION',
    },
    {
        label: 'QUEUE_ANNOUNCEMENT_ARTISTS',
        id: 'QUEUE_ANNOUNCEMENT_ARTISTS',
    },
    {
        label: 'QUEUE_ANNOUNCEMENT_SONGS',
        id: 'QUEUE_ANNOUNCEMENT_SONGS',
    },
    {
        label: 'MUSIC_PROGRAMMER',
        id: 'MUSIC_PROGRAMMER',
    },
    {
        label: 'SONG_ID',
        id: 'SONG_ID',
    },
    {
        label: 'ARTIST_FACTOIDS_PERENNIAL',
        id: 'ARTIST_FACTOIDS_PERENNIAL',
    },
    {
        label: 'SONG_FACTOIDS_PERENNIAL',
        id: 'SONG_FACTOIDS_PERENNIAL',
    },
    {
        label: 'STATION_ID_BUMPER',
        id: 'STATION_ID_BUMPER',
    },
    {
        label: 'SERVICE_ID_BUMPER',
        id: 'SERVICE_ID_BUMPER',
    },
    {
        label: 'ARTIST_VOICE',
        id: 'ARTIST_VOICE',
    },
    {
        label: 'RELEASE_DATE_EXACT',
        id: 'RELEASE_DATE_EXACT',
    },
    {
        label: 'SEGMENT_ID_A',
        id: 'SEGMENT_ID_A',
    },
    {
        label: 'SEGMENT_ID_B',
        id: 'SEGMENT_ID_B',
    },
    {
        label: 'SEGMENT_ID_C',
        id: 'SEGMENT_ID_C',
    },
    {
        label: 'SEGMENT_ID_D',
        id: 'SEGMENT_ID_D',
    },
    {
        label: 'SEGMENT_ID_E',
        id: 'SEGMENT_ID_E',
    },
    {
        label: 'SEGMENT_ID_F',
        id: 'SEGMENT_ID_F',
    },
    {
        label: 'SEGMENT_ID_G',
        id: 'SEGMENT_ID_G',
    },
    {
        label: 'SEGMENT_ID_H',
        id: 'SEGMENT_ID_H',
    },
    {
        label: 'SEGMENT_ID_I',
        id: 'SEGMENT_ID_I',
    },
    {
        label: 'SEGMENT_ID_J',
        id: 'SEGMENT_ID_J',
    },
    {
        label: 'SEGMENT_ID_K',
        id: 'SEGMENT_ID_K',
    },
    {
        label: 'SEGMENT_ID_L',
        id: 'SEGMENT_ID_L',
    },
    {
        label: 'SEGMENT_ID_M',
        id: 'SEGMENT_ID_M',
    },
    {
        label: 'SEGMENT_ID_N',
        id: 'SEGMENT_ID_N',
    },
    {
        label: 'SEGMENT_ID_O',
        id: 'SEGMENT_ID_O',
    },
    {
        label: 'SEGMENT_ID_P',
        id: 'SEGMENT_ID_P',
    },
    {
        label: 'SEGMENT_ID_Q',
        id: 'SEGMENT_ID_Q',
    },
    {
        label: 'SPECIAL_GUEST_A',
        id: 'SPECIAL_GUEST_A',
    },
    {
        label: 'SPECIAL_GUEST_B',
        id: 'SPECIAL_GUEST_B',
    },
    {
        label: 'SPECIAL_GUEST_C',
        id: 'SPECIAL_GUEST_C',
    },
    {
        label: 'SPECIAL_GUEST_D',
        id: 'SPECIAL_GUEST_D',
    },
    {
        label: 'SPECIAL_GUEST_E',
        id: 'SPECIAL_GUEST_E',
    },
    {
        label: 'CO_HOST_A',
        id: 'CO_HOST_A',
    },
    {
        label: 'CO_HOST_B',
        id: 'CO_HOST_B',
    },
    {
        label: 'CO_HOST_C',
        id: 'CO_HOST_C',
    },
    {
        label: 'HOST_GENERIC_COMMENTARY',
        id: 'HOST_GENERIC_COMMENTARY',
    },
    {
        label: 'HOST_SHORT',
        id: 'HOST_SHORT',
    },
    {
        label: 'HOST_LONG',
        id: 'HOST_LONG',
    },
    {
        label: 'DRAFT_DO_NOT_ADD_TO_QCAR',
        id: 'DRAFT_DO_NOT_ADD_TO_QCAR',
    },
    {
        label: 'DRAFT_TO_BE_DELETED',
        id: 'DRAFT_TO_BE_DELETED',
    },
    {
        label: 'SEGMENT_ID_NEW_MUSIC',
        id: 'SEGMENT_ID_NEW_MUSIC',
    },
    {
        label: 'SEGMENT_ID_THROWBACK',
        id: 'SEGMENT_ID_THROWBACK',
    },
    {
        label: 'STATION_INTRO',
        id: 'STATION_INTRO',
    },
    {
        label: 'ARTIST_CALL_TO_ACTION',
        id: 'ARTIST_CALL_TO_ACTION',
    },
    {
        label: 'HINT_INSTRUCTIONS',
        id: 'HINT_INSTRUCTIONS',
    },
    {
        label: 'TEST_TYPE_1_FOR_Q_A',
        id: 'TEST_TYPE_1_FOR_Q_A',
    },
    {
        label: 'TEST_TYPE_2_FOR_Q_A',
        id: 'TEST_TYPE_2_FOR_Q_A',
    },
    {
        label: 'SPOTLIGHT',
        id: 'SPOTLIGHT',
    },
    {
        label: 'VOICE_COMMENT',
        id: 'VOICE_COMMENT',
    },
    {
        label: 'CALL_TO_ACTION',
        id: 'CALL_TO_ACTION',
    },
    {
        label: 'ARTIST_QUEUE_INTRO',
        id: 'ARTIST_QUEUE_INTRO',
    },
    // from https://t.corp.amazon.com/P84068960/overview
    {
        label: 'ALL_HITS_FIID',
        id: 'ALL_HITS_FIID',
    },
    {
        label: 'ALTERNATIVE_HITS_FIID',
        id: 'ALTERNATIVE_HITS_FIID',
    },
    {
        label: 'COUNTRY_HEAT_FIID',
        id: 'COUNTRY_HEAT_FIID',
    },
    {
        label: 'NECTAR_FIID',
        id: 'NECTAR_FIID',
    },
    {
        label: 'PLATINO_FIID',
        id: 'PLATINO_FIID',
    },
    {
        label: 'POP_CULTURE_FIID',
        id: 'POP_CULTURE_FIID',
    },
    {
        label: 'RAP_ROTATION_FIID',
        id: 'RAP_ROTATION_FIID',
    },
    {
        label: 'R&B_ROTATION_FIID',
        id: 'R&B_ROTATION_FIID',
    },
    {
        label: 'ROCK_ARENA_FIID ',
        id: 'ROCK_ARENA_FIID ',
    },
    {
        label: 'MERRY_MIX_FIID',
        id: 'MERRY_MIX_FIID',
    },
    {
        label: 'RAP_ROTATION_REWIND_FIID',
        id: 'RAP_ROTATION_REWIND_FIID',
    },
    // from https://t.corp.amazon.com/P84068960/overview
    // below content types will be specifically used for pinned content in sequencig tab of forge
    // Host pin types 1 to 12
    {
        label: 'HOST_PIN_1',
        id: 'HOST_PIN_1',
    },
    {
        label: 'HOST_PIN_2',
        id: 'HOST_PIN_2',
    },
    {
        label: 'HOST_PIN_3',
        id: 'HOST_PIN_3',
    },
    {
        label: 'HOST_PIN_4',
        id: 'HOST_PIN_4',
    },
    {
        label: 'HOST_PIN_5',
        id: 'HOST_PIN_5',
    },
    {
        label: 'HOST_PIN_6',
        id: 'HOST_PIN_6',
    },
    {
        label: 'HOST_PIN_7',
        id: 'HOST_PIN_7',
    },
    {
        label: 'HOST_PIN_8',
        id: 'HOST_PIN_8',
    },
    {
        label: 'HOST_PIN_9',
        id: 'HOST_PIN_9',
    },
    {
        label: 'HOST_PIN_10',
        id: 'HOST_PIN_10',
    },
    {
        label: 'HOST_PIN_11',
        id: 'HOST_PIN_11',
    },
    {
        label: 'HOST_PIN_12',
        id: 'HOST_PIN_12',
    },
    // Artist pin types 1 to 12
    {
        label: 'ARTIST_PIN_1',
        id: 'ARTIST_PIN_1',
    },
    {
        label: 'ARTIST_PIN_2',
        id: 'ARTIST_PIN_2',
    },
    {
        label: 'ARTIST_PIN_3',
        id: 'ARTIST_PIN_3',
    },
    {
        label: 'ARTIST_PIN_4',
        id: 'ARTIST_PIN_4',
    },
    {
        label: 'ARTIST_PIN_5',
        id: 'ARTIST_PIN_5',
    },
    {
        label: 'ARTIST_PIN_6',
        id: 'ARTIST_PIN_6',
    },
    {
        label: 'ARTIST_PIN_7',
        id: 'ARTIST_PIN_7',
    },
    {
        label: 'ARTIST_PIN_8',
        id: 'ARTIST_PIN_8',
    },
    {
        label: 'ARTIST_PIN_9',
        id: 'ARTIST_PIN_9',
    },
    {
        label: 'ARTIST_PIN_10',
        id: 'ARTIST_PIN_10',
    },
    {
        label: 'ARTIST_PIN_11',
        id: 'ARTIST_PIN_11',
    },
    {
        label: 'ARTIST_PIN_12',
        id: 'ARTIST_PIN_12',
    },
    // News pin types 1 to 4
    {
        label: 'NEWS_PIN_1',
        id: 'NEWS_PIN_1',
    },
    {
        label: 'NEWS_PIN_2',
        id: 'NEWS_PIN_2',
    },
    {
        label: 'NEWS_PIN_3',
        id: 'NEWS_PIN_3',
    },
    {
        label: 'NEWS_PIN_4',
        id: 'NEWS_PIN_4',
    },
];

export const asset_category_options: Select.Option[] = [
    {
        label: 'DEFAULT_ARTIST_IMAGE',
        id: 'DEFAULT_ARTIST_IMAGE',
    },
    {
        label: 'SPOTLIGHT',
        id: 'SPOTLIGHT',
    },
];

export const voice_asset_category_options: Select.Option[] = [
    {
        label: 'VOICE_UPSELL_AUDIO_NEW_RELEASE',
        id: 'VOICE_UPSELL_AUDIO_NEW_RELEASE',
    },
    {
        label: 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG',
        id: 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG',
    },
];

export const experience_type_filter: Select.Option[] = [
    {
        label: 'VOICE_COMMENT',
        id: 'VOICE_COMMENT',
    },
    {
        label: 'NEW_RELEASE',
        id: 'ANNOUNCEMENT',
        disabled: true,
    },
];

export const experience_state_filter: Select.Option[] = [
    {
        label: 'DRAFT',
        id: 'DRAFT',
    },
    {
        label: 'IN REVIEW',
        id: 'REVIEW',
    },
    {
        label: 'APPROVED',
        id: 'APPROVED',
    },
    {
        label: 'REJECTED',
        id: 'REJECTED',
    },
];

export function getSelection(options: Select.Option[], id?: string): Select.Option | null {
    if (!id) {
        return null;
    }
    let selection = null;
    options.forEach((option) => {
        if (option.id === id) {
            selection = option;
        }
    });
    return selection;
}

export function getMultipleSelection(options: Select.Option[], ids?: string[]): Select.Option[] {
    if (!ids || !Array.isArray(ids)) {
        return [];
    }
    return ids.map((id) => getSelection(options, id) as Select.Option).filter((value) => value != null);
}

export function toPolarisV3SelectOption(option: Select.Option): SelectProps.Option {
    return {
        label: option.label,
        value: option.id,
        description: option.description,
    } as SelectProps.Option;
}

export function addNoSelectionOption(options: SelectProps.Option[]): SelectProps.Option[] {
    return [
        {
            label: 'No Selection',
            value: undefined,
            iconName: 'close',
        } as SelectProps.Option,
        ...options,
    ];
}
