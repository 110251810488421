import React from 'react';
import { Props, RouteStateComponent } from './RouteState';
import {
    addToColumnDefinitions,
    addToContentDescriptionGroups,
    ColumnSetting,
    DEFAULT_TABLE_OPTIONS,
    filteringFunction,
    filterTableOptions,
    mapWithColumnDefinitionIds,
    mapWithContentSelectionValues,
    MusicExperienceTableOptions,
    TableOptions,
} from '../utils/tableUtil';
import { ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, CONTENT_SELECTOR_OPTIONS } from '../configs/experiences-table-config';
import { itemError } from './commons/flash-messages';
import { ExperienceState, ExperienceType, MusicExperienceSearchItem } from '@amzn/mousai-service-client';
import { PropsWithDataStage } from './StageContext';
import MousaiClient from '../utils/mousaiUtil';
import { Select, Table, TableFiltering, TablePagination, TableSorting } from '@amzn/awsui-components-react';
import { createPropertyStorage } from '../utils/jsonStorage';
import { TableNoMatchState } from './commons/common-components';

const columnStorage = createPropertyStorage<ColumnSetting[]>('Experiences-Table-Settings');
const optionsStorage = createPropertyStorage<TableOptions>('Experiences-Table-Options');

let cachedItems: MusicExperienceSearchItem[] | undefined;

type State = MusicExperienceTableOptions;

export default class ArtistCommentariesTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columnDefinitions: ARTIST_COMMENTARIES_COLUMN_DEFINITIONS,
            contentSelector: CONTENT_SELECTOR_OPTIONS,
            selectedExperiences: [],
            experiences: [],
            loading: true,
            hasData: false,
            working: false,
            flashbar: [],
            sortingDetail: { sortingColumn: ARTIST_COMMENTARIES_COLUMN_DEFINITIONS[0].id, sortingDescending: true },
            typeFilter: ExperienceType.COMMENTARY,
            stateFilter: ExperienceState.LIVE,
            showScheduleModal: false,
            ...DEFAULT_TABLE_OPTIONS,
        };
    }

    historyState = new RouteStateComponent(this.props, this.state);
    unmounted = false;

    saveColumnWidth = async (e: CustomEvent<Table.ColumnWidthsChangeDetail>) => {
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithColumnDefinitionIds(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, 'width', e.detail.widths, existing),
        );
    };

    saveColumnSelection = async (e: CustomEvent<Table.ContentSelectionChangeDetail>) => {
        if (!e.detail.contentSelection) {
            return;
        }
        const existing = await columnStorage.load();
        columnStorage.save(
            mapWithContentSelectionValues(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, e.detail.contentSelection, existing),
        );
    };

    loadSettings = async () => {
        const columns = (await columnStorage.load()) || [];
        const options = (await optionsStorage.load()) || DEFAULT_TABLE_OPTIONS;
        const columnDefinitions = addToColumnDefinitions(ARTIST_COMMENTARIES_COLUMN_DEFINITIONS, 'width', columns);
        const contentSelector = addToContentDescriptionGroups(CONTENT_SELECTOR_OPTIONS, columns);
        this.setState({ columnDefinitions, contentSelector, ...options });
    };

    saveTableOptions() {
        optionsStorage.save(filterTableOptions(this.state));
    }

    paginationChanged = (e: CustomEvent<TablePagination.PaginationChangeDetail>) => {
        this.setState({ pageSize: e.detail.pageSize }, () => this.saveTableOptions());
    };

    sortingChanged = (e: CustomEvent<TableSorting.SortingChangeDetail>) => {
        this.setState({ sortingDetail: e.detail }, () => this.saveTableOptions());
    };

    wrapLinesChanged = (e: CustomEvent<Table.WrapLinesChangeDetail>) => {
        this.setState({ wrapLines: e.detail.value }, () => this.saveTableOptions());
    };

    typeFilterChanged = (evt: CustomEvent<Select.ChangeDetail>) => {
        const typeFilter = evt.detail.selectedId as ExperienceType;
        this.setState({ typeFilter }, () => this.loadItems(false));
    };

    stateFilterChanged = (evt: CustomEvent<Select.ChangeDetail>) => {
        const stateFilter = evt.detail.selectedId as ExperienceState;
        this.setState({ stateFilter }, () => this.applyFilters());
    };

    applyFilters() {
        if (!this.unmounted) {
            this.setState({
                hasData: true,
                experiences:
                    cachedItems?.filter(
                        (exp) => exp.state === this.state.stateFilter && exp.type === this.state.typeFilter,
                    ) ?? [],
            });
        }
    }

    componentDidMount() {
        this.setState(this.props.history.location.state);
        this.loadItems(true);
        this.loadSettings();
    }

    componentWillUnmount(): void {
        this.unmounted = true;
        if (this.state.loading) {
            cachedItems = undefined;
        }
    }

    componentDidUpdate(prevProps: PropsWithDataStage): void {
        if (this.props.dataStage != prevProps.dataStage) {
            this.setState({ flashbar: [] });
            this.loadItems();
        }
    }

    loadItems = async (useCache?: boolean) => {
        if (useCache && cachedItems) {
            this.setState({
                loading: false,
            });
            this.applyFilters();
            return;
        }
        this.setState({
            hasData: false,
            loading: true,
        });
        this.props.history.replace({ ...this.props.location });
        try {
            const input = {
                query: {
                    experienceTypes: [this.state.typeFilter],
                },
                includeAssets: true,
                includeStormResources: true,
            };
            await MousaiClient.search(input, (experiences) => {
                cachedItems = experiences;
                this.applyFilters();
            });
        } catch (error) {
            this.setState({
                flashbar: [itemError('Failed to load items', error)],
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const disabled = (this.state.loading && !this.state.hasData) || this.state.working;
        return (
            <div>
                <Table
                    columnDefinitions={this.state.columnDefinitions}
                    items={this.state.experiences}
                    stickyHeader={true}
                    resizableColumns={true}
                    onColumnWidthsChange={this.saveColumnWidth}
                    onContentSelectionChange={this.saveColumnSelection}
                    onWrapLinesChange={this.wrapLinesChanged}
                    loading={disabled}
                    loadingText="Loading..."
                    noMatch={<TableNoMatchState />}
                    wrapLines={this.state.wrapLines}
                >
                    <TableFiltering
                        filteringText={this.state.filteringText}
                        filteringFunction={filteringFunction}
                        filteringPlaceholder="Find..."
                        onFilteringChange={this.historyState.filteringChanged}
                    />
                </Table>
            </div>
        );
    }
}
